function $$(selector) {
  let elm: HTMLElement | null = null;
  if (typeof selector === "string") {
    elm = document.querySelector(selector);
  }

  if (selector instanceof HTMLElement) {
    elm = selector;
  }

  if (elm) {
    const cn = elm.getAttribute("s:cn");
    if (cn && cn != "" && typeof window[cn] === "function") {
      const component = new window[cn](elm);
      return new __sui_component(elm, component);
    }
  }
  return null;
}

function __sui_component_root(elm: Element, name: string) {
  return elm.closest(`[s\\:cn=${name}]`);
}

function __sui_state(component) {
  this.handlers = component.watch || {};
  this.Set = async function (key, value, target) {
    const handler = this.handlers[key];
    target = target || component.root;
    if (handler && typeof handler === "function") {
      const stateObj = {
        target: target,
        stopPropagation: function () {
          target.setAttribute("state-propagation", "true");
        },
      };
      await handler(value, stateObj);
      const isStopPropagation = target
        ? target.getAttribute("state-propagation") === "true"
        : false;
      if (isStopPropagation) {
        return;
      }

      let parent = component.root.parentElement?.closest(`[s\\:cn]`);
      if (parent == null) {
        return;
      }

      // Dispatch the state change custom event to parent component
      const event = new CustomEvent("state:change", {
        detail: { key: key, value: value, target: component.root },
      });
      parent.dispatchEvent(event);
    }
  };
}

function __sui_props(elm) {
  this.Get = function (key) {
    if (!elm || typeof elm.getAttribute !== "function") {
      return null;
    }
    const k = "prop:" + key;
    const v = elm.getAttribute(k);
    const json = elm.getAttribute("json-attr-prop:" + key) === "true";
    if (json) {
      try {
        return JSON.parse(v);
      } catch (e) {
        return null;
      }
    }
    return v;
  };

  this.List = function () {
    const props = {};
    if (!elm || typeof elm.getAttribute !== "function") {
      return props;
    }

    const attrs = elm.attributes;
    for (let i = 0; i < attrs.length; i++) {
      const attr = attrs[i];
      if (attr.name.startsWith("prop:")) {
        const k = attr.name.replace("prop:", "");
        const json = elm.getAttribute("json-attr-prop:" + k) === "true";
        if (json) {
          try {
            props[k] = JSON.parse(attr.value);
          } catch (e) {
            props[k] = null;
          }
          continue;
        }
        props[k] = attr.value;
      }
    }
    return props;
  };
}

function __sui_component(elm, component) {
  this.root = elm;
  this.store = new __sui_store(elm);
  this.props = new __sui_props(elm);
  this.state = component ? new __sui_state(component) : {};

  const __self = this;

  // @ts-ignore
  this.$root = new __Query(this.root);

  this.find = function (selector) {
    // @ts-ignore
    return new __Query(__self.root).find(selector);
  };

  this.query = function (selector) {
    return __self.root.querySelector(selector);
  };

  this.queryAll = function (selector) {
    return __self.root.querySelectorAll(selector);
  };

  this.emit = function (name, data) {
    const event = new CustomEvent(name, { detail: data });
    __self.root.dispatchEvent(event);
  };

  this.render = function (name, data, option) {
    // @ts-ignore
    const r = new __Render(__self, option);
    return r.Exec(name, data);
  };
}

function __sui_event_handler(event, dataKeys, jsonKeys, target, root, handler) {
  const data = {};
  target = target || null;
  if (target) {
    dataKeys.forEach(function (key) {
      const value = target.getAttribute("data:" + key);
      data[key] = value;
    });
    jsonKeys.forEach(function (key) {
      const value = target.getAttribute("json:" + key);
      data[key] = null;
      if (value && value != "") {
        try {
          data[key] = JSON.parse(value);
        } catch (e) {
          const message = e.message || e || "An error occurred";
          console.error(`[SUI] Event Handler Error: ${message} `, target);
        }
      }
    });
  }
  handler &&
    handler(event, data, {
      rootElement: root,
      targetElement: target,
    });
}

function __sui_event_init(elm: Element) {
  const bindEvent = (eventElm) => {
    const cn = eventElm.getAttribute("s:event-cn") || "";
    if (cn == "") {
      console.error("[SUI] Component name is required for event binding", elm);
      return;
    }

    // Data keys
    const events: Record<string, string> = {};
    const dataKeys: string[] = [];
    const jsonKeys: string[] = [];
    for (let i = 0; i < eventElm.attributes.length; i++) {
      if (eventElm.attributes[i].name.startsWith("data:")) {
        dataKeys.push(eventElm.attributes[i].name.replace("data:", ""));
      }
      if (eventElm.attributes[i].name.startsWith("json:")) {
        jsonKeys.push(eventElm.attributes[i].name.replace("json:", ""));
      }
      if (eventElm.attributes[i].name.startsWith("s:on-")) {
        const key = eventElm.attributes[i].name.replace("s:on-", "");
        events[key] = eventElm.attributes[i].value;
      }
    }

    // Bind the event
    for (const name in events) {
      const bind = events[name];
      if (cn == "__page") {
        const handler = window[bind];
        const root = document.body;
        const target = eventElm;
        eventElm.addEventListener(name, (event) => {
          __sui_event_handler(event, dataKeys, jsonKeys, target, root, handler);
        });
        continue;
      }

      const component = eventElm.closest(`[s\\:cn=${cn}]`);
      if (typeof window[cn] !== "function") {
        console.error(`[SUI] Component ${cn} not found`, eventElm);
        return;
      }

      // @ts-ignore
      const comp = new window[cn](component);
      const handler = comp[bind];
      const root = comp.root;
      const target = eventElm;
      eventElm.addEventListener(name, (event) => {
        __sui_event_handler(event, dataKeys, jsonKeys, target, root, handler);
      });
    }
  };

  const eventElms = elm.querySelectorAll("[s\\:event]");
  const jitEventElms = elm.querySelectorAll("[s\\:event-jit]");
  eventElms.forEach((eventElm) => bindEvent(eventElm));
  jitEventElms.forEach((eventElm) => bindEvent(eventElm));
}

function __sui_store(elm) {
  elm = elm || document.body;

  this.Get = function (key) {
    return elm.getAttribute("data:" + key);
  };

  this.Set = function (key, value) {
    elm.setAttribute("data:" + key, value);
  };

  this.GetJSON = function (key) {
    const value = elm.getAttribute("json:" + key);
    if (value && value != "") {
      try {
        const res = JSON.parse(value);
        return res;
      } catch (e) {
        const message = e.message || e || "An error occurred";
        console.error(`[SUI] Event Handler Error: ${message}`, elm);
        return null;
      }
    }
    return null;
  };

  this.SetJSON = function (key, value) {
    elm.setAttribute("json:" + key, JSON.stringify(value));
  };

  this.GetData = function () {
    return this.GetJSON("__component_data") || {};
  };
}

async function __sui_backend_call(
  route: string,
  headers: [string, string][] | Record<string, string> | Headers,
  method: string,
  ...args: any
): Promise<any> {
  const url = `/api/__yao/sui/v1/run${route}`;
  headers = {
    "Content-Type": "application/json",
    Referer: window.location.href,
    Cookie: document.cookie,
    ...headers,
  };
  const payload = { method, args };
  try {
    const body = JSON.stringify(payload);
    const response = await fetch(url, { method: "POST", headers, body: body });
    const text = await response.text();
    let data: any | null = null;
    if (text && text != "") {
      data = JSON.parse(text);
    }

    if (response.status >= 400) {
      const message = data.message
        ? data.message
        : `Failed to call ${route} ${method}`;
      const code = data.code ? data.code : 500;
      return Promise.reject({ message, code });
    }

    return Promise.resolve(data);
  } catch (e) {
    const message = e.message ? e.message : `Failed to call ${route} ${method}`;
    const code = e.code ? e.code : 500;
    console.error(`[SUI] Failed to call ${route} ${method}:`, e);
    return Promise.reject({ message, code });
  }
}

/**
 * SUI Render
 * @param component
 * @param name
 */
async function __sui_render(
  component: Component | string,
  name: string,
  data: Record<string, any>,
  option?: RenderOption
): Promise<string> {
  const comp = (
    typeof component === "object" ? component : $$(component)
  ) as Component;

  if (comp == null) {
    console.error(`[SUI] Component not found: ${component}`);
    return Promise.reject("Component not found");
  }

  const elms = comp.root.querySelectorAll(`[s\\:render=${name}]`);
  if (!elms.length) {
    console.error(`[SUI] No element found with s:render=${name}`);
    return Promise.reject("No element found");
  }

  // Set default options
  option = option || {};
  option.replace = option.replace === undefined ? true : option.replace;
  option.showLoader =
    option.showLoader === undefined ? false : option.showLoader;
  option.withPageData =
    option.withPageData === undefined ? false : option.withPageData;

  // Prepare loader
  let loader = `<span class="sui-render-loading">Loading...</span>`;
  if (option.showLoader && option.replace) {
    if (typeof option.showLoader === "string") {
      loader = option.showLoader;
    } else if (option.showLoader instanceof HTMLElement) {
      loader = option.showLoader.outerHTML;
    }
    elms.forEach((elm) => (elm.innerHTML = loader));
  }

  // Prepare data
  let _data = comp.store.GetData() || {};
  if (option.withPageData) {
    // @ts-ignore
    _data = { ..._data, ...__sui_data };
  }

  // get s:route attribute
  const elm = comp.root.closest("[s\\:route]");
  const routeAttr = elm ? elm.getAttribute("s:route") : false;
  const root = document.body.getAttribute("s:public") || "";
  const route = routeAttr ? `${root}${routeAttr}` : window.location.pathname;
  option.component = (routeAttr && comp.root.getAttribute("s:cn")) || "";

  const url = `/api/__yao/sui/v1/render${route}`;
  const payload = { name, data: _data, option };

  // merge the user data
  if (data) {
    for (const key in data) {
      payload.data[key] = data[key];
    }
  }
  const headers = {
    "Content-Type": "application/json",
    Cookie: document.cookie,
  };

  // Native post request to the server
  try {
    const body = JSON.stringify(payload);
    const response = await fetch(url, { method: "POST", headers, body: body });
    const text = await response.text();
    if (!option.replace) {
      return Promise.resolve(text);
    }

    // Set the response text to the elements
    elms.forEach((elm) => {
      elm.innerHTML = text;
      try {
        __sui_event_init(elm);
      } catch (e) {
        const message = e.message || "Failed to init events";
        Promise.reject(message);
      }
    });

    return Promise.resolve(text);
  } catch (e) {
    //Set the error message
    elms.forEach((elm) => {
      elm.innerHTML = `<span class="sui-render-error">Failed to render</span>`;
      console.error("Failed to render", e);
    });
    return Promise.reject("Failed to render");
  }
}

export type Component = {
  root: HTMLElement;
  state: ComponentState;
  store: ComponentStore;
  watch?: Record<string, (value: any, state?: State) => void>;
  Constants?: Record<string, any>;

  [key: string]: any;
};

export type RenderOption = {
  target?: HTMLElement; // default is same with s:render target
  showLoader?: HTMLElement | string | boolean; // default is false
  replace?: boolean; // default is true
  withPageData?: boolean; // default is false
  component?: string; // default is empty
};

export type ComponentState = {
  Set: (key: string, value: any) => void;
};

export type ComponentStore = {
  Get: (key: string) => string;
  Set: (key: string, value: any) => void;
  GetJSON: (key: string) => any;
  SetJSON: (key: string, value: any) => void;
  GetData: () => Record<string, any>;
};

export type State = {
  target: HTMLElement;
  stopPropagation();
};

function $Store(elm) {
  if (!elm) {
    return null;
  }

  if (typeof elm === "string") {
    elm = document.querySelectorAll(elm);
    if (elm.length == 0) {
      return null;
    }
    elm = elm[0];
  }
  // @ts-ignore
  return new __sui_store(elm);
}

function $Query(selector: string | Element): __Query {
  return new __Query(selector);
}

class __Query {
  selector: string | Element | NodeListOf<Element> | undefined = "";
  elements: NodeListOf<Element> | null = null;
  element: Element | null = null;
  constructor(selector: string | Element | NodeListOf<Element>) {
    if (typeof selector === "string") {
      this.selector = selector;
      this.elements = document.querySelectorAll(selector);
      if (this.elements.length > 0) {
        this.element = this.elements[0];
      }
    } else if (selector instanceof NodeList) {
      this.elements = selector;
      if (this.elements.length > 0) {
        this.element = this.elements[0];
      }
    } else {
      this.element = selector;
    }

    this.selector = selector;
  }

  elm(): Element | null {
    return this.element;
  }

  elms(): NodeListOf<Element> | null {
    return this.elements;
  }

  find(selector: string): __Query | null {
    const elm = this.element?.querySelector(selector);
    if (elm) {
      return new __Query(elm);
    }
    return null;
  }

  findAll(selector: string): __Query | null {
    const elms = this.element?.querySelectorAll(selector);
    if (elms) {
      return new __Query(elms);
    }
    return null;
  }

  closest(selector: string): __Query | null {
    const elm = this.element?.closest(selector);
    if (elm) {
      return new __Query(elm);
    }
    return null;
  }

  on(event: string, callback: (event: Event) => void): __Query {
    if (!this.element) {
      return this;
    }
    this.element.addEventListener(event, callback);
    return this;
  }

  $$() {
    if (!this.element) {
      return null;
    }
    const root = this.element.closest("[s\\:cn]");
    if (!root) {
      return null;
    }

    // @ts-ignore
    return $$(root);
  }

  each(callback: (element: __Query, index: number) => void) {
    if (!this.elements) {
      return;
    }
    this.elements.forEach((element, index) => {
      callback(new __Query(element), index);
    });
    return;
  }

  store() {
    if (!this.element || typeof this.element.getAttribute !== "function") {
      return null;
    }

    // @ts-ignore
    return new __sui_store(this.element);
  }

  attr(key) {
    if (!this.element || typeof this.element.getAttribute !== "function") {
      return null;
    }
    return this.element.getAttribute(key);
  }

  data(key) {
    if (!this.element || typeof this.element.getAttribute !== "function") {
      return null;
    }
    return this.element.getAttribute("data:" + key);
  }

  json(key) {
    if (!this.element || typeof this.element.getAttribute !== "function") {
      return null;
    }
    const v = this.element.getAttribute("json:" + key);
    if (!v) {
      return null;
    }
    try {
      return JSON.parse(v);
    } catch (e) {
      console.error(`Error parsing JSON for key ${key}: ${e}`);
      return null;
    }
  }

  prop(key) {
    if (!this.element || typeof this.element.getAttribute !== "function") {
      return null;
    }
    const k = "prop:" + key;
    const v = this.element.getAttribute(k);
    const json = this.element.getAttribute("json-attr-prop:" + key) === "true";
    if (json && v) {
      try {
        return JSON.parse(v);
      } catch (e) {
        console.error(`Error parsing JSON for prop ${key}: ${e}`);
        return null;
      }
    }
    return v;
  }

  hasClass(className) {
    return this.element?.classList.contains(className);
  }

  toggleClass(className) {
    const classes = Array.isArray(className)
      ? className
      : className?.split(" ");
    classes?.forEach((c) => {
      const v = c.replace(/[\n\r\s]/g, "");
      if (v === "") return;
      this.element?.classList.toggle(v);
    });
    return this;
  }

  removeClass(className) {
    const classes = Array.isArray(className)
      ? className
      : className?.split(" ");
    classes?.forEach((c) => {
      const v = c.replace(/[\n\r\s]/g, "");
      if (v === "") return;
      this.element?.classList.remove(v);
    });
    return this;
  }

  addClass(className) {
    const classes = Array.isArray(className)
      ? className
      : className?.split(" ");
    classes?.forEach((c) => {
      const v = c.replace(/[\n\r\s]/g, "");
      if (v === "") return;
      this.element?.classList.add(v);
    });
    return this;
  }

  html(html?: string): __Query | string {
    if (html === undefined) {
      return this.element?.innerHTML || "";
    }
    if (this.element) {
      this.element.innerHTML = html;
    }
    return this;
  }
}

function $Render(comp, option): __Render {
  const r = new __Render(comp, option);
  return r;
}

class __Render {
  comp = null;
  option = null;
  constructor(comp, option) {
    this.comp = comp;
    this.option = option;
  }
  async Exec(name, data): Promise<string> {
    // @ts-ignore
    return __sui_render(this.comp, name, data, this.option);
  }
}

function $Backend(
  route?: string,
  headers?: [string, string][] | Record<string, string> | Headers
) {
  const root = document.body.getAttribute("s:public") || "/";
  route = route || window.location.pathname;
  const re = new RegExp("^" + root);
  route = root + route.replace(re, "");
  return new __Backend(route, headers);
}

class __Backend {
  route = "";
  headers: [string, string][] | Record<string, string> | Headers = {};
  constructor(
    route: string,
    headers: [string, string][] | Record<string, string> | Headers = {}
  ) {
    this.route = route;
    this.headers = headers;
  }

  async Call(method: string, ...args: any): Promise<any> {
    // @ts-ignore
    return await __sui_backend_call(this.route, this.headers, method, ...args);
  }
}

/**
 * YAO Pure JavaScript SDK
 * @author Max<max@iqka.com>
 * @maintainer https://yaoapps.com
 */

/**
 * Yao Object
 * @param {*} host
 */
function Yao(host) {
  this.host = `${
    host || window.location.protocol + "//" + window.location.host
  }/api`;
  this.query = {};
  new URLSearchParams(window.location.search).forEach((key, value) => {
    this.query[key] = value;
  });
}

/**
 * Get API
 * @param {*} path
 * @param {*} params
 */
Yao.prototype.Get = async function (path, params, headers) {
  return this.Fetch("GET", path, params, null, headers);
};

/**
 * Post API
 * @param {*} path
 * @param {*} data
 * @param {*} params
 * @param {*} headers
 */
Yao.prototype.Post = async function (path, data, params, headers) {
  return this.Fetch("POST", path, params, data, headers);
};

/**
 * Download API
 * @param {*} path
 * @param {*} params
 */
Yao.prototype.Download = async function (path, params, savefile, headers) {
  try {
    const blob = await this.Fetch("GET", path, params, null, headers, true);

    var objectUrl = window.URL.createObjectURL(blob);
    let anchor = document.createElement("a");
    document.body.appendChild(anchor);
    anchor.href = objectUrl;
    anchor.download = savefile;
    anchor.click();
    window.URL.revokeObjectURL(objectUrl);
  } catch (err) {
    alert("成功创建导出任务!");
  }
};

/**
 * Fetch API
 * @param {*} method
 * @param {*} path
 * @param {*} params
 * @param {*} data
 * @param {*} headers
 */
Yao.prototype.Fetch = async function (
  method,
  path,
  params,
  data,
  headers,
  isblob
) {
  params = params || {};
  headers = headers || {};
  data = data || null;
  var url = `${this.host}${path}`;
  var queryString = this.Serialize(params);
  if (queryString != "") {
    url = url.includes("?") ? `${url}&${queryString}` : `${url}?${queryString}`;
  }

  const token = this.Token();
  if (token != "") {
    headers["authorization"] = `Bearer ${token}`;
  }

  if (!headers["Content-Type"]) {
    headers["Content-Type"] = "application/json";
  }

  var options: any = {
    method: method,
    mode: "cors", // no-cors, *cors, same-origin
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    credentials: "same-origin", // include, *same-origin, omit
    headers: headers,
    redirect: "follow", // manual, *follow, error
  };

  if (data != null) {
    options["body"] = JSON.stringify(data);
  }

  const resp = await fetch(url, options);
  const type = resp.headers.get("Content-Type") || "";
  if (type.includes("application/json")) {
    try {
      const data = await resp.json();
      return data;
    } catch (err) {
      return { code: resp.status, message: "empty return" };
    }
  } else if (isblob) {
    return resp.blob();
  } else if (type.includes("text/html") || type.includes("text/plain")) {
    return resp.text();
  }
  return resp.text();
};

/**
 * Token API
 * @param {*} path
 * @param {*} params
 */
Yao.prototype.Token = function () {
  var token = sessionStorage.getItem("token") || "";
  if (token == "") {
    return this.Cookie("__tk") || "";
  }
  return token;
};

/**
 * Get Cookie
 * @param {*} cookieName
 * @returns
 */
Yao.prototype.Cookie = function (cookieName) {
  var name = cookieName + "=";
  var decodedCookie = decodeURIComponent(document.cookie);
  var cookieArray = decodedCookie.split(";");

  for (var i = 0; i < cookieArray.length; i++) {
    var cookie = cookieArray[i].trim();
    if (cookie.indexOf(name) === 0) {
      return cookie.substring(name.length, cookie.length);
    }
  }
  return null;
};

Yao.prototype.SetCookie = function (cookieName, cookieValue, expireDays) {
  expireDays = expireDays || 30;
  var d = new Date();
  d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
  var expires = "expires=" + d.toUTCString();
  document.cookie = `${cookieName}=${cookieValue};${expires};path=/`;
};

Yao.prototype.DeleteCookie = function (cookieName) {
  document.cookie = `${cookieName}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;
};

/**
 * Serialize To Query String
 * @param {*} obj
 * @returns
 */
Yao.prototype.Serialize = function (obj) {
  const str: string[] = [];
  for (const p in obj)
    if (obj.hasOwnProperty(p)) {
      str.push(encodeURIComponent(p) + "=" + encodeURIComponent(obj[p]));
    }
  return str.join("&");
};
